import React from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'

const portfolio = () => {
    return (
        <>
            <Navbar />
            <main className="main">
                <div
                    className="site-breadcrumb"
                    style={{ background: "url(assets/img/breadcrumb/01.jpg)" }}
                >
                    <div className="container">
                        <h2 className="breadcrumb-title">Portfolio Single</h2>
                        <ul className="breadcrumb-menu">
                            <li>
                                <a href="index.html">Home</a>
                            </li>
                            <li className="active">Portfolio Single</li>
                        </ul>
                    </div>
                </div>
                <div className="portfolio-single-area py-120">
                    <div className="container">
                        <div className="portfolio-single-wrapper">
                            <div className="row">
                                <div className="col-xl-4 col-lg-4">
                                    <div className="portfolio-sidebar">
                                        <div className="widget portfolio-sidebar-content">
                                            <h4 className="portfolio-sidebar-title">Portfolio Details</h4>
                                            <ul>
                                                <li>
                                                    Category <span>Wedding Rings</span>
                                                </li>
                                                <li>
                                                    Date <span>12 May, 2023</span>
                                                </li>
                                                <li>
                                                    Client Name <span>Roger M Collins</span>
                                                </li>
                                                <li>
                                                    Budget <span>$1250.00</span>
                                                </li>
                                                <li>
                                                    Project Manager <span>Joseph Brehmer</span>
                                                </li>
                                                <li>
                                                    Location <span>New York, USA</span>
                                                </li>
                                                <li>
                                                    Website <span>example.com</span>
                                                </li>
                                                <li>
                                                    Rating
                                                    <div className="rating">
                                                        <a href="#">
                                                            <i className="fas fa-star" />
                                                        </a>
                                                        <a href="#">
                                                            <i className="fas fa-star" />
                                                        </a>
                                                        <a href="#">
                                                            <i className="fas fa-star" />
                                                        </a>
                                                        <a href="#">
                                                            <i className="fas fa-star" />
                                                        </a>
                                                        <a href="#">
                                                            <i className="fas fa-star" />
                                                        </a>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="widget new-portfolio">
                                            <h4>Need Wedding Planner? We Are Ready Contact With Us</h4>
                                            <a href="#" className="new-portfolio-btn">
                                                Contact Now <i className="far fa-arrow-right" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-8 col-lg-8">
                                    <div className="portfolio-details">
                                        <div className="portfolio-details-img mb-30">
                                            <img src="assets/img/portfolio/single.jpg" alt="thumb" />
                                        </div>
                                        <div className="portfolio-details">
                                            <h3 className="mb-30">Wedding Rings</h3>
                                            <p className="mb-20">
                                                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                                                accusantium doloremque laudantium, totam rem aperiam, eaque
                                                ipsa quae ab illo inventore veritatis et quasi architecto
                                                beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem
                                                quia voluptas sit aspernatur aut odit aut fugit, sed quia
                                                consequuntur magni dolores eos qui ratione voluptatem sequi
                                                nesciunt. Neque porro quisquam est, qui dolorem ipsum quia
                                                dolor sit amet, consectetur, adipisci velit, sed quia non
                                                numquam eius modi tempora incidunt ut labore et.
                                            </p>
                                            <p className="mb-20">
                                                But I must explain to you how all this mistaken idea of
                                                denouncing pleasure and praising pain was born and I will give
                                                you a complete account of the system, and expound the actual
                                                teachings of the great explorer of the truth, the
                                                master-builder of human happiness. No one rejects, dislikes,
                                                or avoids pleasure itself, because it is pleasure, but because
                                                those who do not know how to pursue pleasure rationally
                                                encounter consequences that are extremely painful. Nor again
                                                is there anyone who loves or pursues or desires to obtain pain
                                                of itself, because it is pain, but because occasionally
                                                circumstances occur in which toil and pain can procure him
                                                some great pleasure. To take a trivial example
                                            </p>
                                            <div className="row">
                                                <div className="col-md-6 mb-20">
                                                    <img src="assets/img/portfolio/01.jpg" alt="" />
                                                </div>
                                                <div className="col-md-6 mb-20">
                                                    <img src="assets/img/portfolio/02.jpg" alt="" />
                                                </div>
                                            </div>
                                            <p className="mb-20">
                                                Power of choice is untrammelled and when nothing prevents our
                                                being able to do what we like best, every pleasure is to be
                                                welcomed and every pain avoided. But in certain circumstances
                                                and owing to the claims of duty or the obligations of business
                                                it will frequently occur that pleasures have to be repudiated
                                                and annoyances accepted. The wise man therefore always holds
                                                in these matters to this principle of selection.
                                            </p>
                                            <div className="my-4">
                                                <div className="mb-3">
                                                    <h3 className="mb-3">Project Tips</h3>
                                                    <p>
                                                        Aliquam facilisis rhoncus nunc, non vestibulum mauris
                                                        volutpat non. Vivamus tincidunt accumsan urna, vel aliquet
                                                        nunc commodo tristique. Nulla facilisi. Phasellus vel ex
                                                        nulla. Nunc tristique sapien id mauris efficitur, porta
                                                        scelerisque nisl dignissim. Vestibulum ante ipsum primis
                                                        in faucibus orci luctus et ultrices posuere cubilia curae;
                                                        Sed at mollis tellus. Proin consequat, orci nec bibendum
                                                        viverra, ante orci suscipit dolor, et condimentum felis
                                                        dolor ac lectus.
                                                    </p>
                                                </div>
                                                <ul className="portfolio-single-list">
                                                    <li>
                                                        <i className="far fa-check" />
                                                        Fusce justo risus placerat in risus eget tincidunt
                                                        consequat elit.
                                                    </li>
                                                    <li>
                                                        <i className="far fa-check" />
                                                        Nunc fermentum sem sit amet dolor laoreet placerat.
                                                    </li>
                                                    <li>
                                                        <i className="far fa-check" />
                                                        Nullam rhoncus dictum diam quis ultrices.
                                                    </li>
                                                    <li>
                                                        <i className="far fa-check" />
                                                        Integer quis lorem est uspendisse eu augue porta
                                                        ullamcorper dictum.
                                                    </li>
                                                    <li>
                                                        <i className="far fa-check" />
                                                        Quisque tristique neque arcu ut venenatis felis malesuada
                                                        et.
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="my-4">
                                                <h3 className="mb-3">Overview &amp; Challenge</h3>
                                                <p>
                                                    Quisque a nisl id sem sollicitudin volutpat. Cras et commodo
                                                    quam, vel congue ligula. Orci varius natoque penatibus et
                                                    magnis dis parturient montes, nascetur ridiculus mus. Cras
                                                    quis venenatis neque. Donec volutpat tellus lobortis mi
                                                    ornare eleifend. Fusce eu nisl ut diam ultricies accumsan.
                                                    Integer lobortis vestibulum nunc id porta. Curabitur aliquam
                                                    arcu sed ex dictum, a facilisis urna porttitor. Fusce et
                                                    mattis nisl. Sed iaculis libero consequat justo auctor
                                                    iaculis. Vestibulum sed ex et magna tristique bibendum. Sed
                                                    hendrerit neque nec est suscipit, id faucibus dolor
                                                    convallis.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </>
    )
}

export default portfolio