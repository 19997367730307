import React from 'react'
import { Link } from 'react-router-dom';

const Navbar = () => {
    return (
        <>
            <header className="header">
                <div className="main-navigation">
                    <nav className="navbar navbar-expand-lg">
                        <div className="container">
                            <a className="navbar-brand" href="/">
                                <img src="assets\img\slider\1.png" alt="logo" />
                            </a>
                            <div className="mobile-menu-right">
                                <div className="mobile-menu-list">
                                    <a href="#" className="mobile-menu-link search-box-outer">
                                        <i className="far fa-search" />
                                    </a>
                                </div>
                                <button
                                    className="navbar-toggler"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#main_nav"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    <span className="navbar-toggler-btn-icon">
                                        <i className="far fa-bars" />
                                    </span>
                                </button>
                            </div>
                            <div className="collapse navbar-collapse" id="main_nav">
                                <ul className="navbar-nav">
                                    <li className="nav-item dropdown">
                                        <Link
                                            className="nav-link active"
                                            to="/">
                                            Home
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/about">
                                            About
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/gallery">
                                            Gallery
                                        </Link>
                                    </li>
                                    {/* <li className="nav-item dropdown">
                                        <Link
                                            className="nav-link dropdown-toggle"
                                            to="#"
                                            data-bs-toggle="dropdown"
                                        >
                                            Pages
                                        </Link>
                                        <ul className="dropdown-menu fade-down">
                                            <li className="dropdown-submenu">
                                                <Link className="dropdown-item" a="/planner">
                                                    Planners
                                                </Link>
                                            </li>
                                            <li className="dropdown-submenu">
                                                <Link className="dropdown-item" to="/Portfolio">
                                                    Portfolio
                                                </Link>
                                            </li>
                                            <li className="dropdown-submenu">
                                                <Link className="dropdown-item dropdown-toggle" to="#">
                                                    Account
                                                </Link>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <Link className="dropdown-item" to="/login">
                                                            Login
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link className="dropdown-item" to="/register">
                                                            Register
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="/accomodation">
                                                    Accomodation
                                                </Link>
                                            </li>
                                        </ul>
                                    </li> */}
                                    
                                    <li className="nav-item dropdown">
                                        <Link
                                            className="nav-link dropdown-toggle"
                                            to="#"
                                            data-bs-toggle="dropdown"
                                        >
                                            Services
                                        </Link>
                                        <ul className="dropdown-menu fade-down">
                                            <li>
                                                <a className="dropdown-item" href="/wedding">
                                                    Wedding
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" href="/Reception">
                                                Reception
                                                </a>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="/engagement">
                                                Engagement
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="/renewing">
                                                Renewing your Vows
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="/birthday">
                                                Birthday Parties
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="/haldi">
                                                Haldi-Ceremony
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                    {/* <li className="nav-item dropdown">
                                        <a
                                            className="nav-link dropdown-toggle"
                                            href="#"
                                            data-bs-toggle="dropdown"
                                        >
                                            Shop
                                        </a>
                                        <ul className="dropdown-menu fade-down">
                                            <li>
                                                <a className="dropdown-item" href="shop.html">
                                                    Shop
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" href="shop-cart.html">
                                                    Shop Cart
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" href="shop-checkout.html">
                                                    Shop Checkout
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" href="shop-single.html">
                                                    Shop Single
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <a
                                            className="nav-link dropdown-toggle"
                                            href="#"
                                            data-bs-toggle="dropdown"
                                        >
                                            Blog
                                        </a>
                                        <ul className="dropdown-menu fade-down">
                                            <li>
                                                <a className="dropdown-item" href="blog.html">
                                                    Blog
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" href="blog-single.html">
                                                    Blog Single
                                                </a>
                                            </li>
                                        </ul>
                                    </li> */}
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/contact">
                                            Contact
                                        </Link>
                                    </li>
                                </ul>
                                <div className="header-nav-right">
                                    <div className="header-nav-search">
                                        <a href="#" className="header-nav-link search-box-outer">
                                            <i className="far fa-search" />
                                        </a>
                                    </div>
                                    <div className="header-btn">
                                        <Link to="/" className="theme-btn">
                                            Get Quote <i className="far fa-arrow-right" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>

        </>
    )
}

export default Navbar