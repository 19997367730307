import React from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'

const Birthday = () => {
  return (
    <>
      <Navbar />
      <div className='banner-service'>
        <img src="assets\img\slider\birthday (1).jpg" className='w-100' alt="" />
      </div>
      <section className='pt-5 pb-5'>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img src="assets\img\slider\birthday.jpg" className='img-fluid' alt="" />
            </div>
            <div className="col-md-6" style={{ textAlign: "justify" }}>
              <h2 className="text-center" style={{ color: "#06002e" }}>
                Birthday Parties <span style={{ color: "#a88738" }}>Gurunanak Lawn</span>
              </h2>
              <div className="header-img-divider pb-3">
                <img src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='189' height='26' viewBox='0 0 189 26'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%238eaeba' stroke-width='1.5px' d='M86.19%2C2.362L96.98%2C13%2C86.19%2C23.634%2C75.4%2C13Z'/%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%238eaeba' stroke-width='1.5px' d='M94.985%2C2.362L105.775%2C13%2C94.985%2C23.634%2C84.2%2C13Z'/%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%238eaeba' stroke-width='1.5px' d='M103.78%2C2.362L114.57%2C13%2C103.78%2C23.634%2C92.991%2C13Z'/%3E%3Cpath fill-rule='evenodd' fill='%238eaeba' d='M120%2C14V13H523v1H120Z'/%3E%3Cpath fill-rule='evenodd' fill='%238eaeba' d='M-331%2C14V13H69v1H-331Z'/%3E%3C/svg%3E" alt="" />
              </div>
              <p>Celebrate another trip around the sun with a birthday bash that's as unique and special as you are. Our versatile lawn can be transformed to suit any theme or style, allowing you to create the perfect setting for your celebration.</p>
              <p>Whether you're planning an intimate gathering with family or a lavish party with friends, our experienced team will work closely with you to ensure every detail is just right. From delicious cuisine to entertainment options that will delight guests of all ages, we'll go above and beyond to make your birthday party an unforgettable experience.</p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Birthday