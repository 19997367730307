import React,{useEffect} from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'

import $ from 'jquery'; // Import jQuery
import 'magnific-popup'; // Import Magnific Popup
import 'magnific-popup/dist/magnific-popup.css'; // Import Magnific Popup CSS

const Gallery = () => {
  useEffect(() => {
    // Initialize Magnific Popup for image galleries
    $('.popup-gallery').magnificPopup({
        delegate: '.popup-img',
        type: 'image',
        gallery: {
            enabled: true
        }
    });

    // Initialize Magnific Popup for other types of content like videos, iframes, etc.
    $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,
        fixedContentPos: false
    });
}, []);
  return (
    <>
      <Navbar />

      <main className="main">
        <div
          className="site-breadcrumb"
          style={{ background: "url(assets/img/breadcrumb/01.jpg)" }}
        >
          <div className="container">
            <h2 className="breadcrumb-title">Gallery</h2>
            <ul className="breadcrumb-menu">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li className="active">Gallery</li>
            </ul>
          </div>
        </div>
        <div className="gallery-area pt-5 pb-5">
          <div className="gallery-shape">
            <img className="gallery-shape-1" src="assets/img/shape/02.png" alt="" />
            <img className="gallery-shape-2" src="assets/img/shape/03.png" alt="" />
          </div>
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 mx-auto wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay=".25s"
              >
                <div className="site-heading text-center">
                  <span className="site-title-tagline">Our Gallery</span>
                  <h2 className="site-title">
                    Our Captured <span>Moments</span>
                  </h2>
                  <div className="header-img-divider">
                    <img src="assets/img/icon/divider.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="row popup-gallery wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay=".25s"
              data-masonry='{"percentPosition": true }'
            >
              <div className="col-md-4">
                                <div className="gallery-item">
                                    <div className="gallery-img">
                                        <img src="assets\img\slider\service-1.webp" alt="" />
                                    </div>
                                    <div className="gallery-content">
                                        <a
                                            className="popup-img gallery-link"
                                            href="assets\img\slider\service-1.webp"
                                        >
                                            <i className="far fa-plus" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="gallery-item">
                                    <div className="gallery-img">
                                        <img src="assets\img\slider\service-3.webp" alt="" />
                                    </div>
                                    <div className="gallery-content">
                                        <a
                                            className="popup-img gallery-link"
                                            href="assets\img\slider\service-3.webp"
                                        >
                                            <i className="far fa-plus" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="gallery-item">
                                    <div className="gallery-img">
                                        <img src="assets\img\slider\service-2.webp" alt="" />
                                    </div>
                                    <div className="gallery-content">
                                        <a
                                            className="popup-img gallery-link"
                                            href="assets\img\slider\service-2.webp"
                                        >
                                            <i className="far fa-plus" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="gallery-item">
                                    <div className="gallery-img">
                                        <img src="assets\img\slider\service-4.webp" alt="" />
                                    </div>
                                    <div className="gallery-content">
                                        <a
                                            className="popup-img gallery-link"
                                            href="assets\img\slider\service-4.webp"
                                        >
                                            <i className="far fa-plus" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="gallery-item">
                                    <div className="gallery-img">
                                        <img src="assets\img\slider\service-6.webp" alt="" />
                                    </div>
                                    <div className="gallery-content">
                                        <a
                                            className="popup-img gallery-link"
                                            href="assets\img\slider\service-6.webp"
                                        >
                                            <i className="far fa-plus" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="gallery-item">
                                    <div className="gallery-img">
                                        <img src="assets\img\slider\service-5.webp" alt="" />
                                    </div>
                                    <div className="gallery-content">
                                        <a
                                            className="popup-img gallery-link"
                                            href="assets\img\slider\service-5.webp"
                                        >
                                            <i className="far fa-plus" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="gallery-item">
                                    <div className="gallery-img">
                                        <img src="assets\img\slider\service-7.webp" alt="" />
                                    </div>
                                    <div className="gallery-content">
                                        <a
                                            className="popup-img gallery-link"
                                            href="assets\img\slider\service-7.webp"
                                        >
                                            <i className="far fa-plus" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="gallery-item">
                                    <div className="gallery-img">
                                        <img src="assets\img\slider\service-8.webp" alt="" />
                                    </div>
                                    <div className="gallery-content">
                                        <a
                                            className="popup-img gallery-link"
                                            href="assets\img\slider\service-8.webp"
                                        >
                                            <i className="far fa-plus" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="gallery-item">
                                    <div className="gallery-img">
                                        <img src="assets\img\slider\service-4.webp" alt="" />
                                    </div>
                                    <div className="gallery-content">
                                        <a
                                            className="popup-img gallery-link"
                                            href="assets\img\slider\service-4.webp"
                                        >
                                            <i className="far fa-plus" />
                                        </a>
                                    </div>
                                </div>
                            </div>
            </div>
          </div>
        </div>
      </main>


      <Footer />
    </>
  )
}

export default Gallery