import React from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'

const Wedding = () => {
  return (
    <>
      <Navbar />
      <div className='banner-service'>
        <img src="assets\img\slider\wedding banner.jpg" className='w-100' alt="" />
      </div>
      <section className='pt-5 pb-5'>
        <div className="container">
          <h2 className="text-center" style={{ color: "#06002e" }}>
            Wedding at <span style={{ color: "#a88738" }}>Gurunanak Lawn</span>
          </h2>
          <div className="header-img-divider pb-3">
            <img src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='189' height='26' viewBox='0 0 189 26'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%238eaeba' stroke-width='1.5px' d='M86.19%2C2.362L96.98%2C13%2C86.19%2C23.634%2C75.4%2C13Z'/%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%238eaeba' stroke-width='1.5px' d='M94.985%2C2.362L105.775%2C13%2C94.985%2C23.634%2C84.2%2C13Z'/%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%238eaeba' stroke-width='1.5px' d='M103.78%2C2.362L114.57%2C13%2C103.78%2C23.634%2C92.991%2C13Z'/%3E%3Cpath fill-rule='evenodd' fill='%238eaeba' d='M120%2C14V13H523v1H120Z'/%3E%3Cpath fill-rule='evenodd' fill='%238eaeba' d='M-331%2C14V13H69v1H-331Z'/%3E%3C/svg%3E" alt="" />
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className='service_video'>
                <img src="assets\img\slider\wedd-1.jpg" alt="" />
              </div>
            </div>
            <div className="col-md-6 pt-2" style={{ textAlign: "justify" }}>
              <p>Your wedding day is one of the most significant milestones in your life, and at Gurunanak Lawn, we understand the importance of creating a truly magical experience. Nestled amidst lush greenery and surrounded by nature's beauty, our sprawling wedding lawn provides the perfect backdrop for your special day.</p>
              <p>From intimate ceremonies to grand celebrations, our experienced team is dedicated to ensuring every aspect of your wedding is tailored to perfection. We offer a range of customizable packages to suit your specific needs and preferences, allowing you to create the wedding of your dreams without compromising on quality.</p>
              <p>Our expert wedding planners will work closely with you to bring your vision to life, from selecting the perfect décor to curating a menu that delights the senses. With attention to detail and a commitment to excellence, we'll ensure that every moment of your wedding day is filled with love, laughter, and cherished memories.</p>
            </div>
          </div>
        </div>
      </section>

      {/* <section className='pb-5'>
        <div className="container-fluid ltl-frm">
        <div className="row">
            <div className="col-md-12">
              <form className=''>
                <div className="row">
                  <div className="col-md-3">
                    <input
                      type="text"
                      className="cnt"
                      name="name"
                      placeholder="Your Name"
                      required=""
                    />
                  </div>
                  <div className="col-md-3">
                    <input
                      type="email"
                      className="cnt"
                      name="email"
                      placeholder="Email"
                      required=""
                    />
                  </div>
                  <div className="col-md-3">
                    <textarea
                      name="message"
                      cols={3}
                      rows={1}
                      className="cnt"
                      placeholder="Write Your Message"
                      defaultValue={""}
                    />
                  </div>
                  <div className="col-md-2 sbtm">
                    <button className="btn" type="submit">Submit</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section> */}

      <Footer />
    </>
  )
}

export default Wedding