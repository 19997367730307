import React from 'react'
import './Form.css'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'

const Form = () => {
    return (
        <>
            <Navbar />

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h6 className='text-center'>
                            Tax Invoice
                        </h6>
                        <table style={{ width: "100%" }}>
                            <tbody>
                                <tr>
                                    <th colSpan={10}><img src="\assets\img\logo\logo.png" alt="" className='img-fluid' style={{ width: 100 }} />
                                        <h5 className='text-end'>Trafico Analytica Pvt Ltd</h5>
                                        <p className='fs-6 text-end'>
                                            <small>
                                                297, 1st Floor, Chadralok Colony, Aliganj. Near Spencer Mart <br />
                                                Phone no.: 9936663337 Email: traficoanalytica@gmail.com
                                                <br />
                                                GSTIN: 09AAHCT1954A1ZH, State: 09-Uttar Pradesh
                                            </small>
                                        </p></th>
                                </tr>
                                <tr>
                                    <th colSpan={3} className='bg-primary text-dark'>Estimate For
                                    </th>
                                    <th colSpan={6}>
                                        <p className='text-end text-dark'>
                                            <small>
                                                Estimate No:227 <br /> Date: 22-12-2023
                                            </small>
                                        </p>
                                    </th>
                                </tr>
                                <tr className='bg-primary text-light'>
                                    <th>#</th>
                                    <th>Item name</th>
                                    <th>HSN/ SAC</th>
                                    <th>Quantity</th>
                                    <th>Price/ Unit</th>
                                    <th>Amount</th>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td></td>
                                    <td></td>
                                    <td>1</td>
                                    <td>₹ 16,000.00</td>
                                    <td>₹ 16,000.00</td>
                                </tr>
                                <tr>
                                    <th></th>
                                    <td className='text-dark'>Total</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className='text-dark'>₹ 16,000.00</td>
                                </tr>
                                <tr>
                                    <th className='bg-primary text-light text-center'>Estimate Amount In Words
                                    </th>
                                    <th colSpan={6} className='bg-primary text-light'>Amounts:</th>
                                </tr>
                                <tr>
                                    <th rowspan={2} className='text-center'>Sixteen Thousand Rupees only
                                    </th>
                                    <td colSpan={6}>Sub Total </td>
                                </tr>
                                <tr>
                                    <td colSpan={6}>Total</td>
                                </tr>
                                <tr>
                                    <th colSpan={3}><small>Terms and Conditions <br /> Bank Name : KOTAK MAHINDRA BANK LIMITED, <br /> ALIGANJ BRANCH, LUCKNOW

                                        <br /> Bank Account No. : 4412772296
                                        <br /> Bank IFSC code : KKBK0005190
                                        <br /> Account holder's name : TRAFICO ANALYTICA
                                        <br /> PVT. LTD.
                                    </small>                      </th>
                                    <th colSpan={3}>
                                        <img src="\assets\img\logo\logo.png" alt="" className='img-fluid' style={{ width: 100 }} />
                                    </th>
                                    <th>
                                        <img src="\assets\img\logo\logo.png" alt="" className='img-fluid' style={{ width: 100 }} />
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Form