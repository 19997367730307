import React from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'

const Comingsoon = () => {
  return (
    <>
      <Navbar />
        
      <div
  className="coming-soon pt-120 pb-90"
  style={{ background: 'url("assets/img/coming-soon/01.jpg")' }}
>
  <div className="container">
    <div className="row align-items-center justify-content-center">
      <div className="col-12 col-md-9 col-lg-7">
        <div className="coming-soon-content text-white text-center">
          <h1 className="text-white">We're Coming Soon</h1>
          <p className="lead">
            Our website is under construction. We'll be here soon with our new
            awesome site, subscribe to be notified.
          </p>
          <div id="countdown" className="countdown-wrap my-4" />
        </div>
      </div>
    </div>
    <div className="row justify-content-center">
      <div className="col-12 col-md-5 col-lg-5">
        <form className="newsletter-form position-relative">
          <input
            type="text"
            className="input-newsletter form-control"
            placeholder="Enter your email"
            name="email"
            required=""
            autoComplete="off"
          />
          <button type="submit">Subscribe</button>
        </form>
      </div>
    </div>
    <div className="coming-social">
      <a href="#">
        <i className="fa fa-facebook-f" />
      </a>
      <a href="#">
        <i className="fab fa-twitter" />
      </a>
      <a href="#">
        <i className="fab fa-instagram" />
      </a>
      <a href="#">
        <i className="fab fa-linkedin-in" />
      </a>
    </div>
  </div>
</div>


      <Footer />
    </>
  )
}

export default Comingsoon