import React from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'

const Ourstory = () => {
  return (
    <>
    <Navbar />
      
    <main className="main">
  <div
    className="site-breadcrumb"
    style={{ background: "url(assets/img/breadcrumb/01.jpg)" }}
  >
    <div className="container">
      <h2 className="breadcrumb-title">Our Story</h2>
      <ul className="breadcrumb-menu">
        <li>
          <a href="index.html">Home</a>
        </li>
        <li className="active">Our Story</li>
      </ul>
    </div>
  </div>
  <div className="couple-area py-120">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6">
          <div
            className="couple-big wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay=".25s"
          >
            <div className="couple-big-img">
              <img src="assets/img/couple/03.jpg" alt="" />
            </div>
            <div className="couple-big-shape">
              <img
                className="couple-big-shape-1"
                src="assets/img/shape/10.png"
                alt=""
              />
              <img
                className="couple-big-shape-2"
                src="assets/img/shape/11.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <div
            className="couple-left wow fadeInLeft"
            data-wow-duration="1s"
            data-wow-delay=".25s"
          >
            <div className="couple-item">
              <div className="couple-img">
                <img src="assets/img/couple/01.jpg" alt="" />
              </div>
              <div className="couple-content">
                <h4 className="couple-name">Thomas Richardson</h4>
                <p>
                  There are many variations of passages the majority have
                  suffered alteration in some form, by injected humour, or
                  randomised words.{" "}
                </p>
                <div className="couple-social">
                  <a href="#">
                    <i className="fab fa-facebook-f" />
                  </a>
                  <a href="#">
                    <i className="fab fa-twitter" />
                  </a>
                  <a href="#">
                    <i className="fab fa-instagram" />
                  </a>
                  <a href="#">
                    <i className="fab fa-pinterest-p" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <div
            className="couple-right wow fadeInRight"
            data-wow-duration="1s"
            data-wow-delay=".25s"
          >
            <div className="couple-item">
              <div className="couple-img">
                <img src="assets/img/couple/02.jpg" alt="" />
              </div>
              <div className="couple-content">
                <h4 className="couple-name">Angie Mayes</h4>
                <p>
                  There are many variations of passages the majority have
                  suffered alteration in some form, by injected humour, or
                  randomised words.
                </p>
                <div className="couple-social">
                  <a href="#">
                    <i className="fab fa-facebook-f" />
                  </a>
                  <a href="#">
                    <i className="fab fa-twitter" />
                  </a>
                  <a href="#">
                    <i className="fab fa-instagram" />
                  </a>
                  <a href="#">
                    <i className="fab fa-pinterest-p" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="video-area">
    <div className="container-fluid px-0">
      <div
        className="video-content"
        style={{ backgroundImage: "url(assets/img/video/01.jpg)" }}
      >
        <div className="row align-items-center">
          <div className="col-lg-12">
            <div className="video-wrapper">
              <a
                className="play-btn popup-youtube"
                href="https://www.youtube.com/watch?v=ckHzmP1evNU"
              >
                <i className="fas fa-play" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="story-area py-120">
    <div className="container">
      <div className="row">
        <div
          className="col-lg-7 mx-auto wow fadeInDown"
          data-wow-duration="1s"
          data-wow-delay=".25s"
        >
          <div className="site-heading text-center">
            <span className="site-title-tagline">Our Story</span>
            <h2 className="site-title">
              Our Love <span>Story</span>
            </h2>
            <div className="header-img-divider">
              <img src="assets/img/icon/divider.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="story-timeline">
            <div className="story-circle-start" />
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="story-left-content">
                  <span className="story-icon">
                    <i className="flaticon-calendar" />
                  </span>
                  <div className="story-content">
                    <h3>Our First Meet</h3>
                    <span>July 12, 2022</span>
                    <p>
                      There are many variations of passages majority have
                      suffered alteration in some injected humour randomised
                      words which don't look even slightly believable. If you
                      are going to use a passage of Lorem Ipsum, you need to be
                      sure there isn't anything embarrassing hidden in the
                      middle of text.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="story-right-img">
                  <div className="story-img">
                    <img src="assets/img/story/01.jpg" alt="" />
                    <div className="story-img-shape">
                      <img src="assets/img/shape/10.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="story-left-img">
                  <div className="story-img">
                    <img src="assets/img/story/02.jpg" alt="" />
                    <div className="story-img-shape">
                      <img src="assets/img/shape/10.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="story-right-content">
                  <span className="story-icon">
                    <i className="flaticon-heart" />
                  </span>
                  <div className="story-content">
                    <h3>Our First Date</h3>
                    <span>July 20, 2022</span>
                    <p>
                      There are many variations of passages majority have
                      suffered alteration in some injected humour randomised
                      words which don't look even slightly believable. If you
                      are going to use a passage of Lorem Ipsum, you need to be
                      sure there isn't anything embarrassing hidden in the
                      middle of text.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="story-left-content">
                  <span className="story-icon">
                    <i className="flaticon-calendar" />
                  </span>
                  <div className="story-content">
                    <h3>Our Marriage Proposal</h3>
                    <span>July 25, 2022</span>
                    <p>
                      There are many variations of passages majority have
                      suffered alteration in some injected humour randomised
                      words which don't look even slightly believable. If you
                      are going to use a passage of Lorem Ipsum, you need to be
                      sure there isn't anything embarrassing hidden in the
                      middle of text.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="story-right-img">
                  <div className="story-img">
                    <img src="assets/img/story/03.jpg" alt="" />
                    <div className="story-img-shape">
                      <img src="assets/img/shape/10.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="story-left-img">
                  <div className="story-img">
                    <img src="assets/img/story/04.jpg" alt="" />
                    <div className="story-img-shape">
                      <img src="assets/img/shape/10.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="story-right-content">
                  <span className="story-icon">
                    <i className="flaticon-dove" />
                  </span>
                  <div className="story-content">
                    <h3>Our Engagement</h3>
                    <span>July 30, 2022</span>
                    <p>
                      There are many variations of passages majority have
                      suffered alteration in some injected humour randomised
                      words which don't look even slightly believable. If you
                      are going to use a passage of Lorem Ipsum, you need to be
                      sure there isn't anything embarrassing hidden in the
                      middle of text.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6 offset-lg-6">
                <span className="story-icon">
                  <i className="flaticon-wedding-rings" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>


    <Footer />
    </>
  )
}

export default Ourstory