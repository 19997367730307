import React from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'

const Reception = () => {
  return (
    <>
      <Navbar />
      <div className='banner-service'>
        <img src="assets\img\slider\reception (1).jpg" className='w-100' alt="" />
      </div>
      <section className='pt-5 pb-5'>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img src="assets\img\slider\reception.jpg" alt="" />
            </div>
            <div className="col-md-6" style={{ textAlign: "justify" }}>
              <h2 className="text-center" style={{ color: "#06002e" }}>
                Receptions at <span style={{ color: "#a88738" }}>Gurunanak Lawn</span>
              </h2>
              <div className="header-img-divider pb-3">
                <img src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='189' height='26' viewBox='0 0 189 26'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%238eaeba' stroke-width='1.5px' d='M86.19%2C2.362L96.98%2C13%2C86.19%2C23.634%2C75.4%2C13Z'/%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%238eaeba' stroke-width='1.5px' d='M94.985%2C2.362L105.775%2C13%2C94.985%2C23.634%2C84.2%2C13Z'/%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%238eaeba' stroke-width='1.5px' d='M103.78%2C2.362L114.57%2C13%2C103.78%2C23.634%2C92.991%2C13Z'/%3E%3Cpath fill-rule='evenodd' fill='%238eaeba' d='M120%2C14V13H523v1H120Z'/%3E%3Cpath fill-rule='evenodd' fill='%238eaeba' d='M-331%2C14V13H69v1H-331Z'/%3E%3C/svg%3E" alt="" />
              </div>

              <p>After you've exchanged your vows, it's time to celebrate with your loved ones in style. Our spacious lawn provides ample room for dining, dancing, and creating unforgettable memories with friends and family. Whether you envision an elegant soirée or a lively party, our team will work tirelessly to ensure your reception is a reflection of your unique style and personality.</p>
              <p>From exquisite floral arrangements to gourmet cuisine, we'll take care of every detail so you can relax and enjoy the festivities. With state-of-the-art sound systems and customizable lighting options, we'll set the stage for an unforgettable evening of joy and celebration.</p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Reception