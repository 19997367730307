import React from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'

const Tax = () => {
    return (
        <>
            <Navbar />
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h6 className='text-center'>
                            Tax Invoice
                        </h6>
                        <table style={{ width: "100%" }}>
                            <tbody>
                                <tr>
                                    <th colSpan={10}><img src="\assets\img\logo\logo.png" alt="" className='img-fluid' style={{ width: 100 }} />
                                        <h5 className='text-end'>Trafico Analytica Pvt Ltd</h5>
                                        <p className='fs-6 text-end'>
                                            <small>
                                                297, 1st Floor, Chadralok Colony, Aliganj. Near Spencer Mart <br />
                                                Phone no.: 9936663337 Email: traficoanalytica@gmail.com
                                                <br />
                                                GSTIN: 09AAHCT1954A1ZH, State: 09-Uttar Pradesh
                                            </small>
                                        </p></th>
                                </tr>
                                <tr>
                                    <th colSpan={2}>Bill To
                                        <br /><b>The Skin Art</b>
                                        <br />Amren House, 17/1, Ashok Marg, Prem Nagar, Hazratganj, Lucknow, Uttar
                                        Pradesh 226001 <br />GSTIN : 09AJCPT5410G1Z9 <br />State: 09-Uttar Pradesh
                                        <br />Contact No. : 9839060310
                                    </th>
                                    <th colSpan={6}>
                                        <p className='text-end text-dark'>
                                            <small>
                                                Place of supply: 09-Uttar Pradesh<br /><b>Invoice No. : 2023-24/85</b> <br />
                                                <b>Date : 10-01-2024</b></small>
                                        </p>
                                    </th>
                                </tr>
                                <tr className='bg-primary text-light'>
                                    <th>#</th>
                                    <th>Item name</th>
                                    <th>HSN/ SAC</th>
                                    <th>Quantity</th>
                                    <th>Price/ Unit</th>
                                    <th>GST </th>
                                    <th>Amount</th>
                                </tr>
                                <tr>
                                    <th>1</th>
                                    <th>Jan Month the Skin Art Clinic Invoice </th>
                                    <th></th>
                                    <th>1</th>
                                    <th>₹ 20,000.00</th>
                                    <th>₹ 3,600.00 (18%)</th>
                                    <th>₹ 23,600.00</th>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Website Hosting Renewal till Jan 2025 </td>
                                    <td></td>
                                    <td>1</td>
                                    <td>₹ 3,000.00</td>
                                    <td>₹ 540.00 (18%)</td>
                                    <td>₹ 3,540.00</td>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th className='text-dark'>Total</th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th>₹ 4,140.00</th>
                                    <th className='text-dark'>₹ 27,140.00
                                    </th>
                                </tr>
                                <tr>
                                    <th className='bg-primary text-light text-center'>Invoice Amount In Words
                                    </th>
                                    <th colSpan={6} className='bg-primary text-light'>Amounts:</th>
                                </tr>
                                <tr>
                                    <th rowspan={3} className='text-center'>Sixteen Thousand Rupees only
                                    </th>
                                    <td colSpan={6}>Sub Total <span className='end'>₹ 27,140.00</span> </td>
                                </tr>
                                <tr>
                                    <th colSpan={6} className='text-dark'>Total <span className='end'>₹ 27,140.00
                                    </span>
                                    <br />Recived <span className='end'>₹ 27,140.00
                                    </span>
                                    </th>
                                    
                                </tr>
                                <tr>
                                    <td colSpan={6}>Balance  <span className='end'>₹ 27,140.00
                                    </span></td>
                                </tr>
                                <tr>
                                    <th className='bg-primary text-light text-center'>Invoice Amount In Words
                                    </th>
                                    <th colSpan={6} className='bg-primary text-light'>Amounts:</th>
                                </tr>                              
                                <tr>
                                    <th colSpan={3}><small>Terms and Conditions <br /> Bank Name : KOTAK MAHINDRA BANK LIMITED, <br /> ALIGANJ BRANCH, LUCKNOW

                                        <br /> Bank Account No. : 4412772296
                                        <br /> Bank IFSC code : KKBK0005190
                                        <br /> Account holder's name : TRAFICO ANALYTICA
                                        <br /> PVT. LTD.
                                    </small>                      </th>
                                    <th colSpan={3}>
                                        <img src="\assets\img\logo\logo.png" alt="" className='img-fluid' style={{ width: 100 }} />
                                    </th>
                                    <th>
                                        <img src="\assets\img\logo\logo.png" alt="" className='img-fluid' style={{ width: 100 }} />
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Tax