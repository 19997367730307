import React from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const About = () => {
  return (
    <>
      <Navbar />
      <main className="main">
        <div
          className="site-breadcrumb"
          style={{ background: "url(assets/img/breadcrumb/01.jpg)" }}
        >
          <div className="container">
            <h2 className="breadcrumb-title">About Us</h2>
            <ul className="breadcrumb-menu">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li className="active">About Founder</li>
            </ul>
          </div>
        </div>
        <div className="about-area pt-5 pb-5">
          <div className="about-shape">
            <img className="about-shape-1" src="assets/img/shape/04.png" alt="" />
          </div>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div
                  className="about-left wow fadeInLeft"
                  data-wow-duration="1s"
                  data-wow-delay=".25s"
                >
                  <div className="about-img">
                    <img src="
                                        assets\img\slider\foundation.jpeg" alt="" />
                  </div>
                  <div className="about-experience">
                    <h1>
                      25 <span>+</span>
                    </h1>
                    <span className="about-experience-text">Years Of Experience</span>
                  </div>
                  <div className="about-img-shape">
                    <img src="assets/img/shape/01.svg" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="about-right wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay=".25s"
                >
                  <div className="site-heading mb-3">
                    <span className="site-title-tagline">About Us</span>
                    <h2 className="site-title">
                      We Make Your Every <span>Moment</span> Special
                    </h2>

                  </div>
                  <p className="about-text">
                    At Gurunanak Lawn, we understand that life's most precious moments deserve to be celebrated in a setting that is as exquisite as the occasion itself. Nestled amidst lush greenery and surrounded by nature's beauty, our sprawling wedding lawn sets the perfect stage for your dream event.
                  </p>
                  <div className="about-list-wrapper">
                    <ul className="about-list list-unstyled">
                      <li>
                        <div className="about-icon">
                          <span className="fas fa-check-circle" />
                        </div>
                        <div className="about-list-text">
                          <p>Experienced Event Planning: Skilled planners ensure meticulous attention and personalized service.</p>
                        </div>
                      </li>
                      <li>
                        <div className="about-icon">
                          <span className="fas fa-check-circle" />
                        </div>
                        <div className="about-list-text">
                          <p>Professional Staff: Dedicated team ensures smooth event execution, letting you enjoy every moment.</p>
                        </div>
                      </li>
                      <li>
                        <div className="about-icon">
                          <span className="fas fa-check-circle" />
                        </div>
                        <div className="about-list-text">
                          <p>Versatile Event Spaces: Our lawn adapts to weddings, receptions, engagements, and birthdays, ensuring a memorable experience for all.</p>
                        </div>
                      </li>
                      <li>
                        <div className="about-icon">
                          <span className="fas fa-check-circle" />
                        </div>
                        <div className="about-list-text">
                          <p>Attention to Detail: Every aspect of your event is meticulously planned and executed, from seating arrangements to menu selection, to create a flawless experience.</p>
                        </div>
                      </li>
                      <li>
                        <div className="about-icon">
                          <span className="fas fa-check-circle" />
                        </div>
                        <div className="about-list-text">
                          <p>Timely Coordination: Efficient coordination and communication throughout the planning process, ensuring seamless execution on the day of the event.</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="counter-area pt-5">
          <div className="container-fluid">
            <div className="counter-wrapper">
              <div className="row">
                <div className="col-lg-3 col-sm-6">
                  <div className="counter-box">
                    <div className="icon">
                      <i className="flaticon-suitcase" />
                    </div>
                    <div>
                      <span
                        className="counter"
                        data-count="+"
                        data-to={1200}
                        data-speed={3000}
                      >
                        1200
                      </span>
                      <h6 className="title">+ Projects Done </h6>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="counter-box">
                    <div className="icon">
                      <i className="flaticon-wedding-arch" />
                    </div>
                    <div>
                      <span
                        className="counter"
                        data-count="+"
                        data-to={500}
                        data-speed={3000}
                      >
                        500
                      </span>
                      <h6 className="title">+ Happy Couples</h6>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="counter-box">
                    <div className="icon">
                      <i className="flaticon-pastor" />
                    </div>
                    <div>
                      <span
                        className="counter"
                        data-count="+"
                        data-to={1500}
                        data-speed={3000}
                      >
                        1500
                      </span>
                      <h6 className="title">+ Experts Planner</h6>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="counter-box">
                    <div className="icon">
                      <i className="flaticon-placeholder" />
                    </div>
                    <div>
                      <span
                        className="counter"
                        data-count="+"
                        data-to={50}
                        data-speed={3000}
                      >
                        50
                      </span>
                      <h6 className="title">+ Wedding Locations</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="cta-area">
          <div className="container-fluid">
            <div className="cta-wrapper">
              <div className="row">
                <div className="col-lg-8 mx-auto text-center">
                  <div className="cta-text">
                    <h1>We Are Ready To Celebrate Your Wedding</h1>
                    <p>
                      Let us turn your dream wedding into a reality amidst our picturesque lawn and impeccable service.{" "}
                    </p>
                  </div>
                  <a href="/contact" className="theme-btn mt-30">
                    Contact Now <i className="far fa-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="team-area pt-120 p-5">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 mx-auto wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay=".25s"
              >
                <div className="site-heading text-center">
                  <span className="site-title-tagline">Our Planners</span>
                  <h2 className="site-title">
                    Meet Our <span>Planners</span>
                  </h2>
                  <div className="header-img-divider">
                    <img src="assets/img/icon/divider.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-3">
                <div
                  className="team-item wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay=".25s"
                >
                  <div className="team-img">
                    <img src="assets/img/team/01.jpg" alt="thumb" />
                  </div>
                  <div className="team-content">
                    <div className="team-bio">
                      <h5>
                        <a href="#">Edna Craig</a>
                      </h5>
                      <span>Planner</span>
                    </div>
                    <div className="team-social">
                      <a href="#">
                        <i className="fab fa-facebook-f" />
                      </a>
                      <a href="#">
                        <i className="fab fa-twitter" />
                      </a>
                      <a href="#">
                        <i className="fab fa-instagram" />
                      </a>
                      <a href="#">
                        <i className="fab fa-linkedin-in" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div
                  className="team-item wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay=".50s"
                >
                  <div className="team-img">
                    <img src="assets/img/team/02.jpg" alt="thumb" />
                  </div>
                  <div className="team-content">
                    <div className="team-bio">
                      <h5>
                        <a href="#">Jeffrey Cox</a>
                      </h5>
                      <span>CEO &amp; Founder</span>
                    </div>
                    <div className="team-social">
                      <a href="#">
                        <i className="fab fa-facebook-f" />
                      </a>
                      <a href="#">
                        <i className="fab fa-twitter" />
                      </a>
                      <a href="#">
                        <i className="fab fa-instagram" />
                      </a>
                      <a href="#">
                        <i className="fab fa-linkedin-in" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div
                  className="team-item wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay=".75s"
                >
                  <div className="team-img">
                    <img src="assets/img/team/03.jpg" alt="thumb" />
                  </div>
                  <div className="team-content">
                    <div className="team-bio">
                      <h5>
                        <a href="#">Audrey Gaddis</a>
                      </h5>
                      <span>Makeup Artist</span>
                    </div>
                    <div className="team-social">
                      <a href="#">
                        <i className="fab fa-facebook-f" />
                      </a>
                      <a href="#">
                        <i className="fab fa-twitter" />
                      </a>
                      <a href="#">
                        <i className="fab fa-instagram" />
                      </a>
                      <a href="#">
                        <i className="fab fa-linkedin-in" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div
                  className="team-item wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="1s"
                >
                  <div className="team-img">
                    <img src="assets/img/team/04.jpg" alt="thumb" />
                  </div>
                  <div className="team-content">
                    <div className="team-bio">
                      <h5>
                        <a href="#">Rodger Garza</a>
                      </h5>
                      <span>Photographer</span>
                    </div>
                    <div className="team-social">
                      <a href="#">
                        <i className="fab fa-facebook-f" />
                      </a>
                      <a href="#">
                        <i className="fab fa-twitter" />
                      </a>
                      <a href="#">
                        <i className="fab fa-instagram" />
                      </a>
                      <a href="#">
                        <i className="fab fa-linkedin-in" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="instagram-area">
          <div className="container-fluid px-0">
            <div className="instagram-slider owl-carousel owl-theme">
              <div className="instagram-item">
                <div className="instagram-img">
                  <img src="assets/img/instagram/01.jpg" alt="Thumb" />
                  <a href="#">
                    <i className="fab fa-instagram" />
                  </a>
                </div>
              </div>
              <div className="instagram-item">
                <div className="instagram-img">
                  <img src="assets/img/instagram/02.jpg" alt="Thumb" />
                  <a href="#">
                    <i className="fab fa-instagram" />
                  </a>
                </div>
              </div>
              <div className="instagram-item">
                <div className="instagram-img">
                  <img src="assets/img/instagram/03.jpg" alt="Thumb" />
                  <a href="#">
                    <i className="fab fa-instagram" />
                  </a>
                </div>
              </div>
              <div className="instagram-item">
                <div className="instagram-img">
                  <img src="assets/img/instagram/04.jpg" alt="Thumb" />
                  <a href="#">
                    <i className="fab fa-instagram" />
                  </a>
                </div>
              </div>
              <div className="instagram-item">
                <div className="instagram-img">
                  <img src="assets/img/instagram/05.jpg" alt="Thumb" />
                  <a href="#">
                    <i className="fab fa-instagram" />
                  </a>
                </div>
              </div>
              <div className="instagram-item">
                <div className="instagram-img">
                  <img src="assets/img/instagram/06.jpg" alt="Thumb" />
                  <a href="#">
                    <i className="fab fa-instagram" />
                  </a>
                </div>
              </div>
              <div className="instagram-item">
                <div className="instagram-img">
                  <img src="assets/img/instagram/07.jpg" alt="Thumb" />
                  <a href="#">
                    <i className="fab fa-instagram" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="partner-area pt-30 pb-30">
          <div className="container">
            <div className="partner-wrapper partner-slider owl-carousel owl-theme">
              <img src="assets/img/partner/01.png" alt="thumb" />
              <img src="assets/img/partner/01.png" alt="thumb" />
              <img src="assets/img/partner/01.png" alt="thumb" />
              <img src="assets/img/partner/01.png" alt="thumb" />
              <img src="assets/img/partner/01.png" alt="thumb" />
              <img src="assets/img/partner/01.png" alt="thumb" />
              <img src="assets/img/partner/01.png" alt="thumb" />
            </div>
          </div>
        </div> */}
      </main>
      <Footer />
    </>
  )
}

export default About