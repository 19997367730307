import React from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import '../Services/Form.css'

const Register = () => {
    return (
        <>
            <Navbar />
            <main className="main">
                <div
                    className="site-breadcrumb"
                    style={{ background: "url(assets/img/breadcrumb/01.jpg)" }}
                >
                    <div className="container">
                        <h2 className="breadcrumb-title">Register</h2>
                        <ul className="breadcrumb-menu">
                            <li>
                                <a href="index.html">Home</a>
                            </li>
                            <li className="active">Register</li>
                        </ul>
                    </div>
                </div>

                <div className="login-area py-120">
                    <div className="container">
                        <div className="col-md-5 mx-auto">
                            <div className="login-form shadow-lg border">
                                <div className="login-header">
                                    <img src="assets/img/logo/logo.png" alt="" />
                                    <p>Create your Loveo account</p>
                                </div>
                                <form action="#">
                                    <div className="form-group">
                                        <label>Full Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Your Name"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Email Address</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder="Your Email"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Password</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            placeholder="Your Password"
                                        />
                                    </div>
                                    <div className="form-check form-group">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            defaultValue=""
                                            id="agree"
                                        />
                                        <label className="form-check-label" htmlFor="agree">
                                            I agree with the <a href="#">Terms Of Service.</a>
                                        </label>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <button type="submit" className="theme-btn">
                                            <i className="far fa-paper-plane" /> Register
                                        </button>
                                    </div>
                                </form>
                                <div className="login-footer">
                                    <p>
                                        Already have an account? <a href="login.html">Login.</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            {/* <section>
                <div className="container">
                    <div className="background-img">
                        <div className="box">
                            <span />
                            <span />
                            <span />
                            <span />
                            <div className="content">
                                <h2>My animated Border </h2>
                                <p>
                                    <a>
                                        All our modules are designed to play nicely with responsive of
                                        course. At the end of the day it comes down to the theme you use -
                                        our code doesn't get in your way.
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}


            <Footer />
        </>
    )
}

export default Register