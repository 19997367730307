import React from 'react'

const Footer = () => {
    return (
        <>
            <footer className="footer-area">
                <div className="footer-widget">
                    <div className="container">
                        <div className="row footer-widget-wrapper pt-5 pb-3">
                            <div className="col-md-6 col-lg-4">
                                <div className="footer-widget-box about-us">
                                    <a href="#" className="footer-logo">
                                        <img src="assets\img\slider\1.png" alt="" />
                                    </a>
                                    <p className="mb-4">
                                        We are many variations of passages available but the majority have
                                        suffered alteration in some form by injected humour words
                                        believable.
                                    </p>
                                    <div className="col-md-6 align-self-center">
                                        <ul className="footer-social">
                                            <li>
                                                <a href="#">
                                                    <i className="fab fa-facebook-f fb" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <i className="fab fa-twitter tw" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <i className="fab fa-instagram ins" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <i className="fab fa-youtube yu" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-2">
                                <div className="footer-widget-box list">
                                    <h4 className="footer-widget-title">Quick Links</h4>
                                    <ul className="footer-list">
                                        <li>
                                            <a href="/home">
                                                <i className="fas fa-angle-double-right" /> Home
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/about">
                                                <i className="fas fa-angle-double-right" /> About Us
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/gallery">
                                                <i className="fas fa-angle-double-right" /> Gallery
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/contact">
                                                <i className="fas fa-angle-double-right" /> Contact
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="footer-widget-box list">
                                    <h4 className="footer-widget-title">Our Services</h4>
                                    <ul className="footer-list">
                                        <li>
                                            <a href="/wedding">
                                                <i className="fas fa-angle-double-right" />
                                                Wedding
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/reception">
                                                <i className="fas fa-angle-double-right" />
                                                Reception Your Vows
                                            </a>
                                        </li>
                                        <li>
                                            <a href="birthday">
                                                <i className="fas fa-angle-double-right" />
                                                Birhday Parties
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fas fa-angle-double-right" />
                                                Haldi-Ceremony
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fas fa-angle-double-right" /> Interior
                                                Decoration
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fas fa-angle-double-right" /> Wedding Registry
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="footer-widget-box list">
                                    <h4 className="footer-widget-title">Contact Now</h4>
                                    <ul className="footer-contact">
                                        <li>
                                            <i className="far fa-map-marker-alt" />
                                            Guru Nanak Lawn Kursi Road, Extension, near St. Mary Polyclinic, Sector J, Jankipuram, Lucknow, Up 226031
                                        </li>
                                        <li>
                                            <a href="tel:+21236547898">
                                                <i className="far fa-phone" />
                                                +095541 05806
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://live.themewild.com/cdn-cgi/l/email-protection#6d04030b022d08150c001d0108430e0200">
                                                <i className="far fa-envelope" />
                                                <span
                                                    className="__cf_email__"
                                                    data-cfemail="4d24232b220d28352c203d2128632e2220"
                                                >
                                                    [email&nbsp;protected]
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <link
                                    rel="stylesheet"
                                    href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
                                />
                                <a
                                    href="https://wa.me/9554105806?text=I am interested to know more about your services!" class="float" target="_blank"
                                    className="float"
                                    target="_blank"
                                >
                                    <i className="fa fa-whatsapp my-float" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 align-self-center">
                                <p className="copyright-text">
                                    © Copyright 2024 Made with <span id="date" /> <a href="#"> by Digital Nawab </a> All Rights
                                    Reserved.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </>
    )
}

export default Footer