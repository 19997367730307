import './App.css';
import Home from './Home/Home'
import About from './About/About'
import Contact from './Contact/Contact';
import Planner from './Page/Planner'
import Portfolio from './Page/portfolio'
import Form from './Services/Form'
import Tax from './Services/Tax'
import Accomodation from './Page/Accomodation';
import Comingsoon from './Services/Comingsoon'
import Register from './Page/Register'
import Login from './Page/Login'
import Ourstory from './Page/Ourstory';
import Gallery from './Gallery/Gallery'
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import Wedding from './Services/Wedding'
import Reception from './Services/Reception'
import Engagement from './Services/Engagement'
import Renewing from './Services/Renewing'
import Birthday from './Services/Birthday'
import Haldi from './Services/Haldi'

function App() {
  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path='/about' element={<About/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path='/planner' element={<Planner/>} />
        <Route path='/portfolio' element={<Portfolio/>} />
        <Route path='/form' element={<Form/>} />
        <Route path='/Comingsoon' element={<Comingsoon/>} />
        <Route path='/login' element={<Login/>} />
        <Route path='/tax' element={<Tax/>} />
        <Route path='./Ourstory' element={<Ourstory/>} />
        <Route path='/register' element={<Register/>}/>
        <Route path='/gallery' element={<Gallery/>} />
        <Route path='/accomodation' element={<Accomodation />} />
        {/* Services-Start */}
        <Route path='/wedding' element={<Wedding/>} />
        <Route path='/reception' element={<Reception/>} />
        <Route path='/engagement' element={<Engagement/>} />
        <Route path='/renewing' element={<Renewing/>} />
        <Route path='/birthday' element={<Birthday/>} />
        <Route path='/haldi' element={<Haldi/>} />
      </Routes>
    </BrowserRouter>       
    </>
  );
}

export default App;

// import './App.css';
// import Home from './Home/Home'

// function App() {
//   return (
//     <>
//        <Home/>
//     </>
//   );
// }

// export default App;

