import React from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'

const Accomodation = () => {
    return (
        <>
            <Navbar />

            <main className="main">
                <div
                    className="site-breadcrumb"
                    style={{ background: "url(assets/img/breadcrumb/01.jpg)" }}
                >
                    <div className="container">
                        <h2 className="breadcrumb-title">Accomodation</h2>
                        <ul className="breadcrumb-menu">
                            <li>
                                <a href="index.html">Home</a>
                            </li>
                            <li className="active">Accomodation</li>
                        </ul>
                    </div>
                </div>
                <div className="wedding-countdown pt-50 pb-50">
                    <div className="container">
                        <div id="wedding-countdown" className="countdown-wrap my-4" />
                    </div>
                </div>
                <div className="couple-area pb-120">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div
                                    className="couple-big wow fadeInUp"
                                    data-wow-duration="1s"
                                    data-wow-delay=".25s"
                                >
                                    <div className="couple-big-img">
                                        <img src="assets/img/couple/03.jpg" alt="" />
                                    </div>
                                    <div className="couple-big-shape">
                                        <img
                                            className="couple-big-shape-1"
                                            src="assets/img/shape/10.png"
                                            alt=""
                                        />
                                        <img
                                            className="couple-big-shape-2"
                                            src="assets/img/shape/11.png"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div
                                    className="couple-left wow fadeInLeft"
                                    data-wow-duration="1s"
                                    data-wow-delay=".25s"
                                >
                                    <div className="couple-item">
                                        <div className="couple-img">
                                            <img src="assets/img/couple/01.jpg" alt="" />
                                        </div>
                                        <div className="couple-content">
                                            <h4 className="couple-name">Thomas Richardson</h4>
                                            <p>
                                                There are many variations of passages the majority have
                                                suffered alteration in some form, by injected humour, or
                                                randomised words.{" "}
                                            </p>
                                            <div className="couple-social">
                                                <a href="#">
                                                    <i className="fab fa-facebook-f" />
                                                </a>
                                                <a href="#">
                                                    <i className="fab fa-twitter" />
                                                </a>
                                                <a href="#">
                                                    <i className="fab fa-instagram" />
                                                </a>
                                                <a href="#">
                                                    <i className="fab fa-pinterest-p" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div
                                    className="couple-right wow fadeInRight"
                                    data-wow-duration="1s"
                                    data-wow-delay=".25s"
                                >
                                    <div className="couple-item">
                                        <div className="couple-img">
                                            <img src="assets/img/couple/02.jpg" alt="" />
                                        </div>
                                        <div className="couple-content">
                                            <h4 className="couple-name">Angie Mayes</h4>
                                            <p>
                                                There are many variations of passages the majority have
                                                suffered alteration in some form, by injected humour, or
                                                randomised words.
                                            </p>
                                            <div className="couple-social">
                                                <a href="#">
                                                    <i className="fab fa-facebook-f" />
                                                </a>
                                                <a href="#">
                                                    <i className="fab fa-twitter" />
                                                </a>
                                                <a href="#">
                                                    <i className="fab fa-instagram" />
                                                </a>
                                                <a href="#">
                                                    <i className="fab fa-pinterest-p" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cta-area pb-120">
                    <div className="container">
                        <div className="cta-wrapper">
                            <div className="row">
                                <div className="col-lg-8 mx-auto text-center">
                                    <div className="cta-text">
                                        <h1>We Are Ready To Celebrate Your Wedding</h1>
                                        <p>
                                            Lorem ipsum dolor sit amet consectetur adipiscing elit commodo
                                            leo sed scelerisque turpis handful of model sentence structures
                                            to generate which looks reasonable in finibus tellus.{" "}
                                        </p>
                                    </div>
                                    <a href="#" className="theme-btn mt-30">
                                        Contact Now <i className="far fa-arrow-right" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="time-place pb-120">
                    <div className="container">
                        <div className="row">
                            <div
                                className="col-lg-6 mx-auto wow fadeInDown"
                                data-wow-duration="1s"
                                data-wow-delay=".25s"
                            >
                                <div className="site-heading text-center">
                                    <span className="site-title-tagline">Time &amp; Place</span>
                                    <h2 className="site-title">
                                        Wedding Time &amp; <span>Place</span>
                                    </h2>
                                    <div className="header-img-divider">
                                        <img src="assets/img/icon/divider.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row g-5">
                            <div className="col-md-6 col-lg-4">
                                <div
                                    className="time-place-item wow fadeInUp"
                                    data-wow-duration="1s"
                                    data-wow-delay=".25s"
                                >
                                    <div className="time-place-item-shape">
                                        <img src="assets/img/shape/06.png" alt="" />
                                    </div>
                                    <div className="time-place-title">
                                        <h4>Wedding Reception</h4>
                                        <div className="time-place-title-shape">
                                            <img src="assets/img/shape/13.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="time-place-content">
                                        <ul>
                                            <li>
                                                <i className="far fa-clock" />
                                                <b>Date &amp; Time:</b> Sunday, 30 August, 2022 &amp; 10:00AM
                                                - 02:00PM
                                            </li>
                                            <li>
                                                <i className="far fa-location-dot" />
                                                <b>Address:</b> Glow Comunity Centure, 25/B Milford Road, New
                                                York
                                            </li>
                                            <li>
                                                <i className="far fa-phone" />
                                                <b>Phone:</b> +2 123 654 7898
                                            </li>
                                            <li>
                                                <i className="far fa-envelope" />
                                                <b>Email:</b>{" "}
                                                <a
                                                    href="https://live.themewild.com/cdn-cgi/l/email-protection"
                                                    className="__cf_email__"
                                                    data-cfemail="7d14131b123d18051c100d1118531e1210"
                                                >
                                                    [email&nbsp;protected]
                                                </a>
                                            </li>
                                        </ul>
                                        <a href="#" className="theme-btn">
                                            See Map Location
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div
                                    className="time-place-item wow fadeInUp"
                                    data-wow-duration="1s"
                                    data-wow-delay=".50s"
                                >
                                    <div className="time-place-item-shape">
                                        <img src="assets/img/shape/06.png" alt="" />
                                    </div>
                                    <div className="time-place-title">
                                        <h4>Wedding Ceremony</h4>
                                        <div className="time-place-title-shape">
                                            <img src="assets/img/shape/13.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="time-place-content">
                                        <ul>
                                            <li>
                                                <i className="far fa-clock" />
                                                <b>Date &amp; Time:</b> Sunday, 30 August, 2022 &amp; 10:00AM
                                                - 02:00PM
                                            </li>
                                            <li>
                                                <i className="far fa-location-dot" />
                                                <b>Address:</b> Glow Comunity Centure, 25/B Milford Road, New
                                                York
                                            </li>
                                            <li>
                                                <i className="far fa-phone" />
                                                <b>Phone:</b> +2 123 654 7898
                                            </li>
                                            <li>
                                                <i className="far fa-envelope" />
                                                <b>Email:</b>{" "}
                                                <a
                                                    href="https://live.themewild.com/cdn-cgi/l/email-protection"
                                                    className="__cf_email__"
                                                    data-cfemail="4821262e27082d30292538242d662b2725"
                                                >
                                                    [email&nbsp;protected]
                                                </a>
                                            </li>
                                        </ul>
                                        <a href="#" className="theme-btn">
                                            See Map Location
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div
                                    className="time-place-item wow fadeInUp"
                                    data-wow-duration="1s"
                                    data-wow-delay=".75s"
                                >
                                    <div className="time-place-item-shape">
                                        <img src="assets/img/shape/06.png" alt="" />
                                    </div>
                                    <div className="time-place-title">
                                        <h4>Wedding Party</h4>
                                        <div className="time-place-title-shape">
                                            <img src="assets/img/shape/13.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="time-place-content">
                                        <ul>
                                            <li>
                                                <i className="far fa-clock" />
                                                <b>Date &amp; Time:</b> Sunday, 30 August, 2022 &amp; 10:00AM
                                                - 02:00PM
                                            </li>
                                            <li>
                                                <i className="far fa-location-dot" />
                                                <b>Address:</b> Glow Comunity Centure, 25/B Milford Road, New
                                                York
                                            </li>
                                            <li>
                                                <i className="far fa-phone" />
                                                <b>Phone:</b> +2 123 654 7898
                                            </li>
                                            <li>
                                                <i className="far fa-envelope" />
                                                <b>Email:</b>{" "}
                                                <a
                                                    href="https://live.themewild.com/cdn-cgi/l/email-protection"
                                                    className="__cf_email__"
                                                    data-cfemail="6900070f06290c11080419050c470a0604"
                                                >
                                                    [email&nbsp;protected]
                                                </a>
                                            </li>
                                        </ul>
                                        <a href="#" className="theme-btn">
                                            See Map Location
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>


            <Footer />
        </>
    )
}

export default Accomodation