import React from 'react'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'

const Planner = () => {
    return (
        <>
        <Navbar />
            <main className="main">
                <div
                    className="site-breadcrumb"
                    style={{ background: "url(assets/img/breadcrumb/01.jpg)" }}
                >
                    <div className="container">
                        <h2 className="breadcrumb-title">Planner Single</h2>
                        <ul className="breadcrumb-menu">
                            <li>
                                <a href="index.html">Home</a>
                            </li>
                            <li className="active">Planner Single</li>
                        </ul>
                    </div>
                </div>
                <div className="team-single py-120">
                    <div className="container">
                        <div className="team-single-wrapper">
                            <div className="row align-items-center">
                                <div className="col-lg-4">
                                    <div className="team-single-img">
                                        <img src="assets/img/team/01.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="team-single-content">
                                        <div className="team-single-name">
                                            <h3>Malissa Fierro</h3>
                                            <p>Wedding Planner</p>
                                        </div>
                                        <div className="team-single-info">
                                            <ul>
                                                <li>
                                                    <span className="team-single-info-left">Phone:</span>
                                                    <span className="team-single-info-right">
                                                        +2 123 654 7898
                                                    </span>
                                                </li>
                                                <li>
                                                    <span className="team-single-info-left">Email:</span>
                                                    <span className="team-single-info-right">
                                                        <a
                                                            href="https://live.themewild.com/cdn-cgi/l/email-protection"
                                                            className="__cf_email__"
                                                            data-cfemail="c4adaaa2ab84a1bca5a9b4a8a1eaa7aba9"
                                                        >
                                                            [email&nbsp;protected]
                                                        </a>
                                                    </span>
                                                </li>
                                                <li>
                                                    <span className="team-single-info-left">Speciality:</span>
                                                    <span className="team-single-info-right">
                                                        Photography, Wedding Ceremony
                                                    </span>
                                                </li>
                                                <li>
                                                    <span className="team-single-info-left">Experience:</span>
                                                    <span className="team-single-info-right">10 Years</span>
                                                </li>
                                                <li>
                                                    <span className="team-single-info-left">Degree:</span>
                                                    <span className="team-single-info-right">
                                                        Masters In Fashion Design
                                                    </span>
                                                </li>
                                                <li>
                                                    <span className="team-single-info-left">University:</span>
                                                    <span className="team-single-info-right">
                                                        Oxford University
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                        <hr />
                                        <div className="team-single-social">
                                            <a href="#">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                            <a href="#">
                                                <i className="fab fa-twitter" />
                                            </a>
                                            <a href="#">
                                                <i className="fab fa-instagram" />
                                            </a>
                                            <a href="#">
                                                <i className="fab fa-linkedin-in" />
                                            </a>
                                            <a href="#">
                                                <i className="fab fa-youtube" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="team-single-overview">
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="team-single-overview-content">
                                        <h4 className="mb-10">Biography</h4>
                                        <p>
                                            There are many variations of passages of Lorem Ipsum available,
                                            but the majority have suffered alteration in some form, by
                                            injected humour, or randomised words which don't look even
                                            slightly believable. If you are going to use a passage of Lorem
                                            Ipsum, you need to be sure there isn't anything embarrassing
                                            hidden in the middle of text.
                                        </p>
                                        <p className="mt-10">
                                            All the Lorem Ipsum generators on the Internet tend to repeat
                                            predefined chunks as necessary, making this the first true
                                            generator on the Internet. It uses a dictionary of over 200
                                            Latin words, combined with a handful of model sentence
                                            structures, to generate Lorem Ipsum which looks reasonable. The
                                            generated Lorem Ipsum is therefore always free from repetition,
                                            injected humour, or non-characteristic words etc.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="team-single-overview-img">
                                        <img src="assets/img/team/bio.jpg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        <Footer />
        </>
    )
}

export default Planner