import React from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'

const Renewing = () => {
  return (
    <>
      <Navbar />
      <div className='banner-service'>
        <img src="assets\img\slider\reniwing-bann.jpg" className='w-100' alt="" />
      </div>
      <section className='pt-5 pb-5'>
        <div className="container">
        <h2 className="text-center" style={{ color: "#06002e" }}>
                Renewing Your Vows <span style={{ color: "#a88738" }}>Gurunanak Lawn</span>
              </h2>
              <div className="header-img-divider pb-3">
                <img src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='189' height='26' viewBox='0 0 189 26'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%238eaeba' stroke-width='1.5px' d='M86.19%2C2.362L96.98%2C13%2C86.19%2C23.634%2C75.4%2C13Z'/%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%238eaeba' stroke-width='1.5px' d='M94.985%2C2.362L105.775%2C13%2C94.985%2C23.634%2C84.2%2C13Z'/%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%238eaeba' stroke-width='1.5px' d='M103.78%2C2.362L114.57%2C13%2C103.78%2C23.634%2C92.991%2C13Z'/%3E%3Cpath fill-rule='evenodd' fill='%238eaeba' d='M120%2C14V13H523v1H120Z'/%3E%3Cpath fill-rule='evenodd' fill='%238eaeba' d='M-331%2C14V13H69v1H-331Z'/%3E%3C/svg%3E" alt="" />
              </div>
          <div className="row">
            <div className="col-md-6">
              <img src="assets\img\slider\Renewing your Vows,.jpg" alt="" />
            </div>
            <div className="col-md-6 pt-2" style={{ textAlign: "justify" }}>
              <p>Whether you're celebrating a milestone anniversary or simply reaffirming your love and commitment, Gurunanak Lawn provides the perfect setting for renewing your vows. Surrounded by the tranquility of nature and the warmth of your closest friends and family, you can reaffirm your love in a ceremony that is both meaningful and memorable.</p>
              <p>Our dedicated team will work closely with you to create a personalized and heartfelt ceremony that reflects the unique bond you share. From elegant decorations to heartfelt speeches, we'll ensure that every detail is tailored to perfection, allowing you to focus on what truly matters – the love you share with your partner.</p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Renewing