import React from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'

const Engagement = () => {
  return (
    <>
      <Navbar />
      <div className='banner-service'>
        <img src="assets\img\slider\engagment (1).jpg" className='w-100' alt="" />
      </div>
      <section className='pt-5 pb-5'>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img src="assets\img\slider\engage.jpg" className='img-fluid' alt="" />
            </div>
            <div className="col-md-6" style={{ textAlign: "justify" }}>
              <h2 className="text-center" style={{ color: "#06002e" }}>
                Engagement at <span style={{ color: "#a88738" }}>Gurunanak Lawn</span>
              </h2>
              <div className="header-img-divider pb-3">
                <img src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='189' height='26' viewBox='0 0 189 26'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%238eaeba' stroke-width='1.5px' d='M86.19%2C2.362L96.98%2C13%2C86.19%2C23.634%2C75.4%2C13Z'/%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%238eaeba' stroke-width='1.5px' d='M94.985%2C2.362L105.775%2C13%2C94.985%2C23.634%2C84.2%2C13Z'/%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%238eaeba' stroke-width='1.5px' d='M103.78%2C2.362L114.57%2C13%2C103.78%2C23.634%2C92.991%2C13Z'/%3E%3Cpath fill-rule='evenodd' fill='%238eaeba' d='M120%2C14V13H523v1H120Z'/%3E%3Cpath fill-rule='evenodd' fill='%238eaeba' d='M-331%2C14V13H69v1H-331Z'/%3E%3C/svg%3E" alt="" />
              </div>
              <p>Begin your journey towards forever with a romantic and intimate engagement celebration at Gurunanak Lawn. Surrounded by the natural beauty of our enchanting lawn, you and your loved one can revel in the excitement and anticipation of this special moment.</p>
              <p>Our experienced team will assist you in creating a personalized and memorable event that perfectly captures the magic of your love story. From elegant décor to delectable cuisine, we'll ensure that every detail is executed flawlessly, leaving you free to savor every precious moment.</p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Engagement