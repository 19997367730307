import React from 'react'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'

const Contact = () => {
  return (
    <>
      <Navbar />
      <main className="main">
        <div
          className="site-breadcrumb"
          style={{ background: "url(assets/img/breadcrumb/01.jpg)" }}
        >
          <div className="container">
            <h2 className="breadcrumb-title">Contact Us</h2>
            <ul className="breadcrumb-menu">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li className="active">Contact Us</li>
            </ul>
          </div>
        </div>
        <div className="contact-area py-120">
          <div className="container">
            <div className="contact-wrapper">
              <div className="row">
                <div className="col-lg-4">
                  <div className="contact-content shadow-lg">
                    <div className="contact-info">
                      <div className="contact-info-icon">
                        <i className="fal fa-map-marker-alt" />
                      </div>
                      <div className="contact-info-content">
                        <h5>Office Address</h5>
                        <p>Guru Nanak Lawn Kursi Road, Extension, near St. Mary Polyclinic, Sector J, Jankipuram, Lucknow, Uttar Pradesh 226031</p>
                      </div>
                    </div>
                    <div className="contact-info">
                      <div className="contact-info-icon">
                        <i className="fal fa-phone" />
                      </div>
                      <div className="contact-info-content">
                        <h5>Call Us</h5>
                        <p>095541 05806</p>
                      </div>
                    </div>
                    <div className="contact-info">
                      <div className="contact-info-icon">
                        <i className="fal fa-envelope" />
                      </div>
                      <div className="contact-info-content">
                        <h5>Email Us</h5>
                        <p>
                          <a
                            href="https://live.themewild.com/cdn-cgi/l/email-protection"
                            className="__cf_email__"
                            data-cfemail="0b62656d644b6e736a667b676e25686466"
                          >
                            [email&nbsp;protected]
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="contact-info">
                      <div className="contact-info-icon">
                        <i className="fal fa-clock" />
                      </div>
                      <div className="contact-info-content">
                        <h5>Open Time</h5>
                        <p>Mon - Sat (10.00AM - 05.30PM)</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 align-self-center">
                  <div className="contact-form shadow-lg">
                    <div className="contact-form-header">
                      <h2>Get In <span>Touch</span></h2>
                      <p>
                        It is a long established fact that a reader will be distracted
                        by the readable content of a page randomised words which don't
                        look even slightly when looking at its layout.{" "}
                      </p>
                    </div>
                    <form
                      method="post"
                      action="https://live.themewild.com/Loveo/assets/php/contact.php"
                      id="contact-form"
                    >
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              placeholder="Your Name"
                              required=""
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              placeholder="Your Email"
                              required=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <select className="form-select form-control" name="service">
                          <option value="">Choose Service</option>
                          <option value={1}>Wedding</option>
                          <option value={2}>Reception Your Vows</option>
                          <option value={3}>Engagement</option>
                          <option value={4}>Renewing</option>
                          <option value={5}>Birthday Parties</option>
                          <option value={6}>Haldi-Ceremony</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <textarea
                          name="message"
                          cols={30}
                          rows={5}
                          className="form-control"
                          placeholder="Write Your Message"
                          defaultValue={""}
                        />
                      </div>
                      <button type="submit" className="theme-btn">
                        Send Message <i className="far fa-paper-plane" />
                      </button>
                      <div className="col-md-12 mt-3">
                        <div className="form-messege text-success" />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="gallery-shape">
                <img className="gallery-shape-1" src="assets/img/shape/02.png" alt="" />
                <img className="gallery-shape-2" src="assets/img/shape/03.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="contact-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3557.27021420177!2d80.9625072748007!3d26.92664685949273!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3999579f61df1a31%3A0x827f9f7930b5a41b!2sGuru%20Nanak%20Marriage%20Lawn!5e0!3m2!1sen!2sin!4v1710249087550!5m2!1sen!2sin"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          />
        </div>
      </main>
      <Footer />
    </>
  )
}

export default Contact